import { useRouter } from 'next/navigation';
import { useRef, useState } from 'react';

import { UserAvatar } from '@/components/global/avatar';
import Button from '@/components/global/button';
import { useCurrentOrganization } from '@/components/global/current-organization';
import { useGlobalState } from '@/components/global/global-state';
import Icon from '@/components/global/icon';
import { ActionLink } from '@/components/global/link';
import { NewFeatureTooltip } from '@/components/global/new-feature-tooltip';
import { PulseWrapper } from '@/components/global/pulse-wrapper';
import { DEFAULT_SITE_LINK_STYLING } from '@/components/global/site-link';
import { useNewFeatureAnnouncement } from '@/components/providers/NewFeature';
import useOutsideClick from '@/helpers/hooks/useOutsideClick';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { logOutCurrentUser } from '@/helpers/logout';
import cn from '@/lib/cn';
import { NEW_TEAMS_USER_PROFILE_DROPDOWN_TEAM_LINK, NewFeatureAnnouncementTypes } from '@/lib/constants/new-features';
import { TooltipPosition as NewTooltipPosition } from '@/lib/constants/tooltip';
import { type ButtonClickTrackingProperties } from '@/types/tracking';

const LINK_CLASSES =
    'text-analyst-gray font-brand-md visited:text-analyst-gray visited:underline hover:text-analyst-purple transition-colors px-4 py-1.5 rounded-full hover:bg-analyst-lavender hover:text-thematic-purple whitespace-nowrap';

interface UserProfileDropdownProps {
    className?: string;
    menuClassName?: string;
    toggleClassName?: string;
    hideLogout?: boolean;
    trackingProperites: Pick<ButtonClickTrackingProperties, 'section'>;
}

const UserProfileDropdown = ({
    className,
    menuClassName,
    toggleClassName,
    hideLogout = false,
    trackingProperites = {} as ButtonClickTrackingProperties,
}: UserProfileDropdownProps) => {
    const { hasAnnouncementBeenViewed, updateNewFeatures } = useNewFeatureAnnouncement();
    const newFeatureCTAAnnouncementHasBeenViewed = hasAnnouncementBeenViewed(
        NewFeatureAnnouncementTypes.NEW_TEAMS_PROFILE_DROPDOWN_LINK
    );
    const { categories, components, eventTypes } = usePosthogTracking();
    const { currentOrganizationDashboardUrl } = useCurrentOrganization();
    const ref = useRef(null);
    const router = useRouter();
    const { globalState, setGlobalState } = useGlobalState();
    const { currentUser } = globalState;
    const [showDropdown, setShowDropdown] = useState(!newFeatureCTAAnnouncementHasBeenViewed);
    const hideDropdown = () => setShowDropdown(false);
    const toggleDropdown = () => setShowDropdown(!showDropdown);
    const closeFeatureTooltip = (hideFeatureTooltip = false) => {
        hideDropdown();
        hideFeatureTooltip && updateNewFeatures(NewFeatureAnnouncementTypes.NEW_TEAMS_PROFILE_DROPDOWN_LINK, true);
    };
    const linkAction = (url: string, hideFeatureTooltip = false) => {
        closeFeatureTooltip(hideFeatureTooltip);
        router.push(url);
    };

    useOutsideClick(ref, () => closeFeatureTooltip(true));
    return (
        <div
            ref={ref}
            className={cn(
                'hover:cursor-pointer px-2 rounded-full border-2 border-analyst-gray h-12 flex items-center justify-center bg-white',
                className
            )}
        >
            <div className="relative">
                <Button
                    type="action"
                    color="transparent"
                    className="border-0 p-0 flex items-center gap-4"
                    onClick={toggleDropdown}
                    tracking={{
                        eventType: showDropdown ? eventTypes.CLOSE_DROPDOWN_MENU : eventTypes.OPEN_DROPDOWN_MENU,
                        trackingProperties: {
                            category: categories.DROPDOWN_MENU_ACTION,
                            component: components.USER_PROFILE_DROPDOWN_TOGGLE,
                            ...trackingProperites,
                        },
                    }}
                >
                    <UserAvatar
                        url={currentUser?.avatarUrl || ''}
                        name={currentUser?.name || ''}
                        className="w-9 h-9 mb-0 text-base rounded-full overflow-hidden"
                    />

                    <Icon
                        type="caret"
                        color="analystPurple"
                        size="xxs"
                        className={cn('-rotate-90 -translate-x-1/2', toggleClassName)}
                    />
                </Button>
                {showDropdown && (
                    <menu
                        className={cn(
                            'bg-analyst-white shadow-md border border-brand-gray-med min-w-fit absolute py-4 px-2 top-14 left-0 md:right-0 md:left-auto rounded-lg flex flex-col animate-fadeIn',
                            menuClassName
                        )}
                    >
                        {currentOrganizationDashboardUrl && (
                            <NewFeatureTooltip
                                onClose={hideDropdown}
                                featureDescription={NEW_TEAMS_USER_PROFILE_DROPDOWN_TEAM_LINK}
                                tooltipPosition={NewTooltipPosition.LEFT}
                                featureKey={NewFeatureAnnouncementTypes.NEW_TEAMS_PROFILE_DROPDOWN_LINK}
                                showTooltip={true}
                                tooltipClassName={cn('w-80 ', {
                                    '-translate-x-full -translate-y-18 -left-6': showDropdown,
                                    'hidden -z-10': newFeatureCTAAnnouncementHasBeenViewed,
                                })}
                                className={cn({
                                    static: showDropdown,
                                })}
                                tagCopy="Update"
                            >
                                <PulseWrapper
                                    removePulse={newFeatureCTAAnnouncementHasBeenViewed}
                                    className={cn('w-full rounded-full transition-all', {
                                        'absolute right-0 bottom-0 transition-all': !showDropdown,
                                        'z-10': showDropdown,
                                    })}
                                >
                                    <ActionLink
                                        onClick={() => linkAction(currentOrganizationDashboardUrl, true)}
                                        className={LINK_CLASSES}
                                    >
                                        Team dashboard
                                    </ActionLink>
                                </PulseWrapper>
                            </NewFeatureTooltip>
                        )}

                        <ActionLink
                            key="account-link"
                            onClick={() => linkAction('/my-account/profile')}
                            className={LINK_CLASSES}
                        >
                            My account
                        </ActionLink>
                        {!hideLogout && (
                            <button
                                onClick={() => logOutCurrentUser({ onSuccess: router.refresh, setGlobalState })}
                                className={cn(
                                    DEFAULT_SITE_LINK_STYLING,
                                    LINK_CLASSES,
                                    'text-analyst-blue font-brand-bold text-left'
                                )}
                                key="logout"
                            >
                                Log out
                            </button>
                        )}
                    </menu>
                )}
            </div>
        </div>
    );
};

export default UserProfileDropdown;
