'use client';

import { useMemo } from 'react';

import LinkedInLogo from '@/assets/logos/linkedin-icon-logo.svg';
import XLogo from '@/assets/logos/x-icon-logo.svg';
import { Paragraph } from '@/components/dom/text-elements';
import { Container, ContainerSize } from '@/components/global/container';
import { useGlobalState } from '@/components/global/global-state';
import { NavigationLink } from '@/components/global/link';
import ThematicLogoLink from '@/components/global/thematic-logo-link';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { getAppStartPath } from '@/helpers/paths';
import cn from '@/lib/cn';
import { THEMATIC_TWITTER_URL } from '@/lib/constants';
import { MAIN_FOOTER_LINKS, MAIN_NAV_RESEARCH_LINKS, MainFooterLinkGroups } from '@/lib/constants/navigation';

interface MainFooterProps {
    className?: string;
}

interface ColumnHeadingProps {
    children: React.ReactNode;
    className?: string;
}

const ColumnHeading = ({ children, className }: ColumnHeadingProps) => (
    <Paragraph
        className={cn('text-analyst-black text-lg md:text-xl xl:text-[22px] leading-1 font-brand-md mb-0', className)}
    >
        {children}
    </Paragraph>
);

const MainFooter = ({ className }: MainFooterProps) => {
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;
    const isLoggedIn = !!currentUser && !currentUserLoading;
    const organizationId = currentUser?.primaryOrganization?.id;
    const { locations } = usePosthogTracking();
    const trackingProperties = {
        section: locations.GLOBAL_FOOTER,
    };
    const researchNavLinks = useMemo(() => {
        return MAIN_NAV_RESEARCH_LINKS.subMenu.map((link) => {
            return {
                ...link,
                href: getAppStartPath({ isLoggedIn, mainActionTab: link.tab, organizationId }),
            };
        });
    }, [isLoggedIn, organizationId]);

    return (
        <footer className={cn('py-4 md:pt-10 md:pb-20 mt-auto', className)}>
            <Container
                size={ContainerSize['8xl']}
                className="flex flex-wrap flex-col gap-y-8 lg:flex-row items-start justify-between lg:gap-4"
            >
                <div className="flex flex-col gap-5 lg:flex-row">
                    <ThematicLogoLink
                        useCubeLogo={true}
                        className="bg-analyst-black rounded-full w-16 h-16 xl:w-20 xl:h-20 p-2 flex items-center justify-center col-span-1"
                        iconClassName="fill-white h-8 xl:h-12"
                        iconInnerWrapperClassName="items-center justify-center"
                    />

                    <div className="flex flex-col gap-5">
                        <ColumnHeading>Follow our journey and product updates</ColumnHeading>

                        <div className="flex gap-5">
                            <NavigationLink
                                href="https://www.linkedin.com/company/gothematic/"
                                title="Thematic - LinkedIn Page"
                                target="_blank"
                                trackingProperties={{
                                    ...trackingProperties,
                                    iconLogo: 'LinkedIn',
                                }}
                            >
                                <LinkedInLogo className="w-8 h-8" />
                            </NavigationLink>
                            <NavigationLink
                                href={THEMATIC_TWITTER_URL}
                                title="Thematic - X Page"
                                target="_blank"
                                trackingProperties={{
                                    ...trackingProperties,
                                    iconLogo: 'XTwitter',
                                }}
                            >
                                <XLogo className="w-8 h-8" />
                            </NavigationLink>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row flex-wrap gap-y-5 gap-x-10 lg:gap-x-16 md:justify-between">
                    {MAIN_FOOTER_LINKS.map(({ group, navLinks }, index: number) => {
                        const links =
                            group.key === MainFooterLinkGroups.PRODUCT ? [...researchNavLinks, ...navLinks] : navLinks;

                        return (
                            <div
                                className="flex flex-col gap-5"
                                key={`footer-column-group-${index}`}
                            >
                                <ColumnHeading>{group.label}</ColumnHeading>

                                {links.map(({ label, ...restNavLinkProps }, navLinkIndex: number) => (
                                    <NavigationLink
                                        key={`footer-column-${index}-nav-link-${navLinkIndex}`}
                                        title={`Thematic - ${label}`}
                                        className="font-brand-md text-analyst-dark-lavender hover:text-thematic-purple"
                                        trackingProperties={{
                                            ...trackingProperties,
                                            additionalTrackingProperties: {
                                                group,
                                            },
                                            label,
                                        }}
                                        {...restNavLinkProps}
                                    >
                                        {label}
                                    </NavigationLink>
                                ))}
                            </div>
                        );
                    })}
                </div>
            </Container>
        </footer>
    );
};

export default MainFooter;
