'use client';

import { usePathname } from 'next/navigation';
import React, { createContext, RefObject, useContext, useRef, useState } from 'react';

import { AnalystProvider } from '@/components/analyst/analyst-provider';
import { CurrentOrganizationSelector } from '@/components/global/current-organization-selector';
import { GlobalNavigationBar } from '@/components/global/global-navigation-bar';
import { useGlobalState } from '@/components/global/global-state';
import MainFooter from '@/components/global/main-footer';
import { ResearchSidebar } from '@/components/global/research-sidebar';
import UserProfileDropdown from '@/components/global/user-profile-dropdown';
import { IndexBuilderContextProvider } from '@/components/index-builder/useIndexBuilderContext';
import { NewFeatureModal } from '@/components/modals/new-feature-modal';
import { ModalProvider } from '@/components/providers/Modal';
import { useNewFeatureAnnouncement } from '@/components/providers/NewFeature';
import { ResearchProvider } from '@/components/providers/Research';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { AVAILABLE_PATHNAMES_FOR_RESEARCH, LOGIN_PROTECTED_PATHNAMES } from '@/lib/constants/general';
import { NewFeatureAnnouncementTypes } from '@/lib/constants/new-features';
import { RESEARCH_CTA_NEW_FEATURE_DESCRIPTION } from '@/lib/constants/research';

interface MainContentContextValue {
    intersectionRef: RefObject<HTMLElement>;
    updateIntersectionRef: (ref: RefObject<HTMLElement>) => void;
}

export const MainContentContext = createContext({} as MainContentContextValue);

export const MainContentProvider = ({ children }: { children: React.ReactNode }) => {
    const startRef = useRef<HTMLDivElement>(null);
    const [intersectionRef, setIntersectionRef] = useState<RefObject<HTMLElement>>(startRef);
    const updateIntersectionRef = (ref: RefObject<HTMLElement>) => setIntersectionRef(ref);
    const value: MainContentContextValue = { intersectionRef, updateIntersectionRef };
    return (
        <MainContentContext.Provider value={value}>
            {children}
            <div ref={startRef} />
        </MainContentContext.Provider>
    );
};

export const useMainContentContext = () => {
    const context = useContext(MainContentContext);

    if (!context) {
        throw new Error('useMainContentContext must be used within a MainContentProvider');
    }

    return context;
};

export const MainContent = ({ children }: { children: React.ReactNode }) => {
    const pathname = usePathname();
    const { locations } = usePosthogTracking();
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;
    const isLoggedIn = !!currentUser && !currentUserLoading;
    const { hasAnnouncementBeenViewed } = useNewFeatureAnnouncement();
    const newFeatureCTAAnnouncementHasBeenViewed = hasAnnouncementBeenViewed(
        NewFeatureAnnouncementTypes.NEW_TEAMS_PROFILE_DROPDOWN_LINK
    );
    const isLoginProtected =
        (!!pathname && LOGIN_PROTECTED_PATHNAMES.some((protectedPath) => pathname.startsWith(protectedPath))) || false;
    const showChildren = !isLoginProtected || isLoggedIn;
    const canShowNewResearchFeatureCallout = AVAILABLE_PATHNAMES_FOR_RESEARCH.some(
        (pagePathname) => pathname?.startsWith(pagePathname)
    );

    return (
        <AnalystProvider>
            <IndexBuilderContextProvider rawBuilderIndexId="create">
                <ResearchProvider>
                    <ModalProvider>
                        <div className="flex flex-col min-h-full">
                            <CurrentOrganizationSelector />

                            <div className="relative flex justify-end w-full">
                                <GlobalNavigationBar />

                                {currentUser && !currentUserLoading && (
                                    <UserProfileDropdown
                                        className={cn(
                                            'hidden animate-fadeIn opacity-0 lg:inline-flex lg:opacity-100 sticky top-1/2 z-200 -translate-y-1/2 right-10',
                                            {
                                                'translate-y-3': isLoggedIn && currentUser.isThematicAdmin,
                                                'translate-y-6': isLoggedIn && !currentUser.isThematicAdmin,
                                                'z-300': !newFeatureCTAAnnouncementHasBeenViewed,
                                            }
                                        )}
                                        trackingProperites={{
                                            section: locations.GLOBAL_NAV,
                                        }}
                                    />
                                )}
                            </div>

                            {showChildren && children}

                            <MainFooter className="mt-auto" />

                            <div id="root" />

                            <ResearchSidebar />
                        </div>

                        {canShowNewResearchFeatureCallout && (
                            <NewFeatureModal
                                featureDescription={RESEARCH_CTA_NEW_FEATURE_DESCRIPTION}
                                featureKey={NewFeatureAnnouncementTypes.RESEARCH_CTA}
                            />
                        )}
                    </ModalProvider>
                </ResearchProvider>
            </IndexBuilderContextProvider>
        </AnalystProvider>
    );
};
