import { MutableRefObject, useEffect } from 'react';

import { Nullable } from '@/types/nullable';

const useOutsideClick = (ref: MutableRefObject<Nullable<HTMLElement>>, callback: () => void, type = 'click') => {
    const handleClick: EventListener = (e: MouseEvent | Event) => {
        if (ref.current && !e.composedPath().includes(ref.current as Node)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener(type, handleClick);

        return () => {
            document.removeEventListener(type, handleClick);
        };
    });
};

export default useOutsideClick;
