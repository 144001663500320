'use client';

import React, { ComponentType, createContext, FC, useState } from 'react';

import { JSXElementProps, PropValue } from '@/types/general';
import { Nullable } from '@/types/nullable';

// Constants
const DEFAULT_MODAL_STATE = {
    modal: null,
    props: null,
};

interface ModalProviderProps {
    children: React.ReactNode;
}

// Define a generic type parameter for the modal component props
interface ModalStateType<T extends Record<string, PropValue> = Record<string, PropValue>> {
    props?: Nullable<T & JSXElementProps>;
    modal: Nullable<ComponentType<T & JSXElementProps> | (() => JSX.Element)>;
}

export type ModalContextType = ModalStateType & {
    hideModal: () => void;
    isVisible: boolean;
    showModal: (modalState: ModalStateType) => void;
};

// Main
export const ModalContext = createContext<Nullable<ModalContextType>>(null);

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
    const [modalState, setModalState] = useState<ModalStateType>(DEFAULT_MODAL_STATE);
    const showModal = ({ props, modal }: ModalStateType) => {
        document.body.classList.add('no-scroll');

        setModalState({
            modal,
            props,
        });
    };
    const hideModal = () => {
        if (modalState.modal) {
            document.body.classList.remove('no-scroll');
            setModalState(DEFAULT_MODAL_STATE);
        }
    };

    const { props, modal: CurrentModal } = modalState;
    const isVisible = !!CurrentModal;
    const modalContextValue: ModalContextType = {
        hideModal,
        isVisible,
        showModal,
        ...modalState,
    };

    return (
        <ModalContext.Provider value={modalContextValue}>
            {children}
            {CurrentModal && <CurrentModal {...props} />}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context: Nullable<ModalContextType> = React.useContext(ModalContext);

    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }

    return context;
};
